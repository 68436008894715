import React from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { useIndexQuery } from '@/api';
import NovaImage from '@/components/novaImage';

const Hero = () => {
  const { t } = useTranslation();
  const { data: indexData, isLoading: indexLoading } = useIndexQuery();

  const dotClass =
    'bg-pri-100 absolute h-1 w-1 lg:h-2 lg:w-2 rounded-full -top-[13px]';
  const lineClass = 'bg-pri-100 absolute h-[2px] bottom-1';

  return (
    <div className='py-8'>
      <div className='container'>
        <NovaImage
          src={indexData?.indexBanner ?? ''}
          alt='banner'
          width='0'
          height='0'
          sizes='100vw'
          className='h-auto w-full'
        />
        <div className='mt-[100px] flex w-full items-center justify-center'>
          <div className='relative w-[60%]'>
            <Image
              src='/image/landing/hero-slogan.png'
              alt='slogan'
              width='0'
              height='0'
              sizes='100vw'
              className='h-auto w-full'
            />
            <div className={clsx(dotClass, 'right-[25%]')} />
            <div className={clsx(dotClass, 'right-[19%] ')} />
            <div className={clsx(dotClass, 'right-[13%]')} />
            <div className={clsx(dotClass, 'right-[7%]')} />
            <div className={clsx(lineClass, '-right-[5%] w-[110%]')} />
            <div
              className={clsx(lineClass, '-right-[5%] w-[105%] -rotate-1')}
            />
          </div>
        </div>
        <div className='title-20r mt-4 text-center'>
          <p>{t('landing.hero.title-1')}</p>
          <p className='mt-2'>{t('landing.hero.title-2')}</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
