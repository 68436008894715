import React from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

const WishItem = ({
  index,
  className,
}: {
  className?: string;
  index: number;
}) => {
  const { t } = useTranslation();
  const title = `landing.wish.item-${index}-title`;
  const content = `landing.wish.item-${index}-content`;
  const label = `landing.wish.item-${index}-label`;

  const labelTitle = t(label).slice(0, 2);
  const labelContent = t(label).slice(2);

  return (
    <div
      className={clsx(
        className,
        'relative h-[300px] w-[384px] px-6 pt-6 lg:h-[317px]'
      )}
    >
      <div className='flex flex-row justify-between'>
        <p className='title-36b'>{t(title)}</p>
        <div className='title-20m flex flex-col items-end text-right text-black'>
          <div className='bg-[#FF8F47] px-2 py-1'>{labelTitle}</div>
          <div className='bg-[#FF8F47] px-2 py-1'>{labelContent}</div>
        </div>
      </div>
      <div className='title-20r mt-4 lg:mt-10'>{t(content)}</div>
      <Image
        src='/image/landing/wish-item-bg.png'
        alt='item-bg'
        fill
        className='absolute left-0 top-0 -z-10 shadow-lg'
      />
    </div>
  );
};

const Wish = () => {
  const { t } = useTranslation();

  return (
    <div className='relative mt-[100px] w-full overflow-x-clip xl:max-h-[950px]'>
      <Image
        src='/image/landing/wish-bg.png'
        alt='bg'
        width={0}
        height={0}
        className='absolute bottom-0 left-1/2 -z-10 h-auto w-full min-w-[1920px] -translate-x-1/2'
      />
      <div className='title-48b flex items-center justify-center'>
        <div className='relative'>
          {t('landing.wish.title')}
          <Image
            src='/image/landing/wish-arrow.png'
            alt='arrow'
            width={65}
            height={65}
            className='absolute -left-3 bottom-1/2 z-10 -translate-x-full translate-y-1/2'
          />
          <Image
            src='/image/landing/wish-wave.png'
            alt='arrow'
            width={300}
            height={20}
            className='absolute bottom-0 z-10 translate-y-full'
          />
        </div>
      </div>
      <div className='container mt-[70px] flex flex-col items-center justify-center gap-x-6 gap-y-4 pb-16 lg:flex-row'>
        <div className='flex flex-col gap-y-4 lg:gap-y-9'>
          <WishItem index={0} />
          <WishItem index={1} />
          <WishItem index={4} className='xl:hidden' />
        </div>
        <div className='flex flex-col gap-y-4 lg:gap-y-9 xl:mt-24'>
          <WishItem index={2} />
          <WishItem index={3} />
          <WishItem index={5} className='xl:hidden' />
        </div>
        <div className='hidden flex-col gap-y-4 lg:gap-y-9 xl:mt-12 xl:flex'>
          <WishItem index={4} />
          <WishItem index={5} />
        </div>
      </div>
    </div>
  );
};

export default Wish;
