import React from 'react';
import { motion } from 'framer-motion';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { PrimaryButton } from '@/components/button';
import Link from '@/components/link';

const images = [
  '/image/landing/channel-list.png',
  '/image/landing/channel-list.png',
  '/image/landing/channel-list.png',
];

const Channel = () => {
  const { t } = useTranslation();

  const animationVariants = {
    animate: {
      x: ['0%', '-100%'],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: 'loop',
          duration: 60,
          ease: 'linear',
        },
      },
    },
  };

  return (
    <div className='border-b border-border-base'>
      <div className='section-padding title-24r container flex flex-col items-center justify-center'>
        <p className='text-center'>
          {t('landing.channel.desc-1')}
          <br />
          {t('landing.channel.desc-2')}
          <br />
          {t('landing.channel.desc-3')}
        </p>
        <p className='title-48b my-8 tracking-wider text-pri-100'>
          {t('landing.channel.title')}
        </p>
        {/* <Image
        src='/image/landing/channel-list.png'
        alt='list'
        width={1200}
        height={100}
        className='object-contain'
      /> */}
        <div className='w-full max-w-[1200px] overflow-hidden'>
          <motion.div
            variants={animationVariants}
            animate='animate'
            className='flex h-[92px] w-[4700px] flex-row'
          >
            {images.map((src, index) => (
              <Image
                key={index}
                src={src}
                alt={`Image ${index}`}
                width={4700}
                height={92}
                className='shrink-0'
              />
            ))}
          </motion.div>
        </div>
        <PrimaryButton
          className='mt-8'
          onClick={() => {
            // go to channel
            // window.open('/channel', '_self');
          }}
        >
          <Link href='/channel'>{t('landing.channel.button')}</Link>
        </PrimaryButton>
      </div>
    </div>
  );
};

export default Channel;
