import { useMemo } from 'react';
import DOMPurify from 'isomorphic-dompurify';

const useSanitizedHtml = (htmlContent: string) => {
  const sanitizedHtml = useMemo(() => {
    // 1. Replace escaped quotes ( \" -> " )
    let unescapedHtml = htmlContent.replace(/\\"/g, '"');

    // 2. Convert <oembed> tags to <iframe> for YouTube videos and center them
    unescapedHtml = unescapedHtml.replace(
      /<oembed[^>]+url="([^"]+)"[^>]*><\/oembed>/g,
      (match, url) => {
        if (url.includes('youtube.com') || url.includes('youtu.be')) {
          const videoId = url.split('v=')[1]?.split('&')[0]; // Extract video ID from URL
          return `
          <figure style="display: flex; justify-content: center;">
            <iframe 
              width="560" 
              height="315" 
              src="https://www.youtube.com/embed/${videoId}" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen>
            </iframe>
          </figure>
        `;
        }
        return ''; // If it's not a supported oembed URL, return empty
      }
    );

    // 3. Remove class="table" from <figure> elements
    unescapedHtml = unescapedHtml.replace(
      /<figure\s+class="table"(.*?)>/g,
      '<figure$1>'
    );

    unescapedHtml = unescapedHtml.replace(
      /<table(.*?)>/g,
      '<table$1 style="border-collapse: collapse; width: 100%;">'
    );
    unescapedHtml = unescapedHtml.replace(
      /<th(.*?)>/g,
      '<th$1 style="border: 1px solid #ddd; padding: 8px;">'
    );
    unescapedHtml = unescapedHtml.replace(
      /<td(.*?)>/g,
      '<td$1 style="border: 1px solid #ddd; padding: 8px;">'
    );

    // 5. Add default styles for <ul>, <ol>, and <li> elements
    unescapedHtml = unescapedHtml.replace(
      /<ul(.*?)>/g,
      '<ul$1 style="list-style-type: disc; padding-left: 20px;">'
    );
    unescapedHtml = unescapedHtml.replace(
      /<ol(.*?)>/g,
      '<ol$1 style="list-style-type: decimal; padding-left: 20px;">'
    );
    unescapedHtml = unescapedHtml.replace(
      /<li(.*?)>/g,
      '<li$1 style="margin-bottom: 5px;">'
    );

    // 3. Sanitize the HTML content, allowing 'a' tags, 'href' attributes, and 'iframe'
    const cleanHtml = DOMPurify.sanitize(unescapedHtml, {
      ALLOWED_TAGS: [
        'h1',
        'h2',
        'span',
        'blockquote',
        'p',
        'strong',
        'img',
        'figure',
        'iframe',
        'a',
        'div',
        'br',
        'table',
        'tbody',
        'tr',
        'td',
        'th',
        's',
        'sup',
        'sub',
        'ol',
        'ul',
        'li',
        'u',
        'i',
      ],
      ALLOWED_ATTR: [
        'style',
        'src',
        'width',
        'height',
        'class',
        'alt',
        'href',
        'target',
        'frameborder',
        'allow',
        'allowfullscreen',
      ],
    });

    return cleanHtml;
  }, [htmlContent]);

  return sanitizedHtml;
};

export default useSanitizedHtml;
