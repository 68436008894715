import React from 'react';
import Image, { ImageProps } from 'next/image';

import Skeleton from '@/components/skeleton';

const NovaImage = ({
  src,
  alt,
  height,
  width,
  className,
  ...rest
}: ImageProps & { className?: string }) => {
  if (!src) {
    return <Skeleton height={height} width={width} className={className} />;
  }

  const completeSrc = `${process.env.NEXT_PUBLIC_IMAGE_ENDPOINT}/${src}`;

  return (
    <Image
      src={completeSrc}
      alt={alt}
      height={height}
      width={width}
      {...rest}
      className={className}
    />
  );
};

export default NovaImage;
