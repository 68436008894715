import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { Book, useBooksQuery } from '@/api';
import { PrimaryButton } from '@/components/button';
import Carousel from '@/components/carousel';
import NovaImage from '@/components/novaImage';

const BookItem = ({ data }: { data: Book }) => {
  const { image, title, author, href } = data;

  const handleClick = () => {
    window.open(href, '_blank');
  };

  return (
    <button
      className='group/book w-[250px] shrink-0 px-2 text-left'
      onClick={handleClick}
    >
      <div className='relative h-[250px] w-full'>
        <NovaImage
          src={image}
          alt={`bool-${title}`}
          fill
          className='object-contain duration-300 group-hover/book:scale-105'
        />
      </div>
      <p className='title-20r mt-4 line-clamp-2'>{title}</p>
      <p className='title-20r mt-2 text-[#777]'>{author}</p>
    </button>
  );
};

const responsive = {
  desktopLg: {
    breakpoint: { max: 3000, min: 1280 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1280, min: 1000 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1000, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Share = () => {
  const { t } = useTranslation();
  const { data: booksData, isLoading: booksLoading } = useBooksQuery();

  return (
    <div className='section-padding container px-14'>
      <p className='title-48b text-center'>{t('landing.share.title')}</p>
      {booksData && booksData.data.length > 0 && (
        <Carousel className=' h-[400px]' responsive={responsive}>
          {booksData.data.map((book, index) => (
            <BookItem key={book.title} data={book} />
          ))}
        </Carousel>
      )}
      <div className='flex justify-center'>
        <PrimaryButton
          onClick={() => {
            window.open(
              'https://www.scbooks.com.tw/#/search/series/SC8A/1',
              '_blank'
            );
          }}
        >
          {t('landing.share.button')}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default Share;
