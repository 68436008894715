import React from 'react';
import LoadingSkeleton, { SkeletonProps } from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

const Skeleton = (props: SkeletonProps) => {
  return <LoadingSkeleton {...props} />;
};

export default Skeleton;
