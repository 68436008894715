import React, { PropsWithChildren } from 'react';
import MultiCarousel, {
  ArrowProps,
  ButtonGroupProps,
  CarouselProps,
} from 'react-multi-carousel';
import Image from 'next/image';
import { twMerge } from 'tailwind-merge';

import 'react-multi-carousel/lib/styles.css';

const Carousel = ({
  className,
  children,
  ...props
}: PropsWithChildren<CarouselProps & { className?: string }>) => {
  return (
    <div className='flex flex-col items-center justify-center'>
      <MultiCarousel
        className={twMerge('w-full', className)}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        transitionDuration={500}
        arrows={false}
        customButtonGroup={<ButtonGroup />}
        renderButtonGroupOutside={true}
        {...props}
      >
        {children}
      </MultiCarousel>
    </div>
  );
};

const CustomRightArrow = ({ onClick }: ArrowProps) => {
  return (
    <button
      onClick={() => onClick?.()}
      className='absolute right-2 h-14 w-14 -translate-y-1/2 duration-150 hover:scale-105'
    >
      <Image
        src='/image/landing/share-arrow-right.png'
        alt='arrow-right'
        width={56}
        height={56}
      />
    </button>
  );
};

const CustomLeftArrow = ({ onClick }: ArrowProps) => {
  return (
    <button
      onClick={() => onClick?.()}
      className='absolute left-2 h-14 w-14 -translate-y-1/2 duration-150 hover:scale-105'
    >
      <Image
        src='/image/landing/share-arrow-left.png'
        alt='arrow-left'
        width={56}
        height={56}
      />
    </button>
  );
};

const ButtonGroup = ({
  next,
  previous,
  goToSlide,
  ...rest
}: ButtonGroupProps) => {
  return (
    <div className='container absolute'>
      <CustomLeftArrow onClick={() => previous?.()} />
      <CustomRightArrow onClick={() => next?.()} />
    </div>
  );
};

export default Carousel;
export { ButtonGroup };
