import React, { ReactNode, useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import clsx from 'clsx';

import { useFAQsQuery } from '@/api';
import ArrowDown from '@/assets/icon/arrow_down.svg';
import ArrowUp from '@/assets/icon/arrow_up.svg';
import useSanitizedHtml from '@/hooks/useSanitizedHtml';

const TextItem = ({
  title,
  content,
  htmlContent,
  className,
}: {
  title: string;
  content?: string;
  htmlContent?: string;
  className?: string;
}) => {
  const sanitizedHtmlContent = useSanitizedHtml(htmlContent || '');

  return (
    <div
      className={clsx(
        className,
        'flex flex-row items-start gap-x-4 py-4 xl:py-8'
      )}
    >
      <p className='title-24b'>{title}</p>
      <p className='title-20r'>
        {content && content}
        {htmlContent && (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizedHtmlContent,
            }}
          />
        )}
      </p>
    </div>
  );
};

const FaqItem = ({ index, q, a }: { index: number; q: string; a: string }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Collapsible.Root
      className=' border-b border-[#939391] px-4 xl:px-8'
      open={open}
      onOpenChange={setOpen}
    >
      <Collapsible.Trigger asChild>
        <div
          className={clsx(
            open && 'text-pri-100',
            'flex cursor-pointer flex-row justify-between'
          )}
        >
          <TextItem title='Q.' content={q} />
          <div className='py-4 xl:py-8'>
            {open ? (
              <ArrowUp className='h-6 w-6' />
            ) : (
              <ArrowDown className='h-6 w-6' />
            )}
          </div>
        </div>
      </Collapsible.Trigger>
      <Collapsible.Content>
        <TextItem title='A.' htmlContent={a} />
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

const Faq = () => {
  const { data: faqsData, isLoading: faqsLoading } = useFAQsQuery();

  return (
    <div className='section-padding bg-[#F9F9F9]'>
      <div className='container'>
        <p className='title-48b text-center'>FAQ</p>
        <div className='mt-12 border-t border-[#939391] xl:mt-24'>
          {faqsData &&
            faqsData.data.map((faq, index) => (
              <FaqItem key={index} index={index} q={faq.Q} a={faq.A} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
