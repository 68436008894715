import { Channel, Faq, Hero, Share, Wish } from '@/components/landing';
import { getStaticPaths, makeStaticProps } from '@/get-static';

export default function Home() {
  return (
    <main>
      <Hero />
      <Wish />
      <Channel />
      <Share />
      <Faq />
    </main>
  );
}

const getStaticProps = makeStaticProps();
export { getStaticPaths, getStaticProps };
